import React from 'react'
import NavBar from "../components/NavBar";
import elrouby from '../IMG-20221112-WA0269.jpg'
import Footer from "../components/footer";
import Important from "../components/important";
 const Mahoud = () => {
  return (
    <>
    <NavBar/>
    <div className="mahmod">
                    <div className="box">
                      <div className="container">
                         <div className="center">
                         <div className="img_box">
                            <img src={elrouby} alt="" />
                            <h2>المنطقة الزرقاء للامم المتحدة</h2>
                        </div>
                       </div>
                            <div className="text">
                                <p>محاضر بكلية الحقوق جامعة الاسكندرية و المحامى المتخصص بالقانون الجنائى والمحاكم العسكرية ومحاكم امن الدولة طوارئ والمتخصص فى ادارة الشئون القانونية للشركات 
                                  <br/>انشأ مؤسسة محمود الروبى فى عام 2017 باختيار نخبة من افضل المحامون المتخصصون فى جميع فروع القانون</p>
                                <h4>الحياة العامة :</h4>
                                <p>كان مساعداً لعضو مجلس نواب فترة دراسته بالجامعة وحصل على جائزة الطالب المثالى وجائزة افضل مقال وافضل امين اتحاد طلاب  ورئيس تحرير مجلة الكلية ورئيس لجنة حقوق الانسان باتحاد طلاب بجامعة الاسكندرية وسفيراً للجامعة بوزارة التعليم العالى ضمن سفراء النوايا الحسنة للجامعات المصرية ثم حصل على جائزة الطالب المثالى لجامعات مصر  من وزارة التعليم العالى و تخرج من جامعة الاسكندرية  وحصل منها على درع التفوق الدراسى ثم التحق بماجستير القانون العام عام ٢٠١٧  
تم انتخابه رئيساً اللجنة التشريعية والدستورية ببرلمان الشباب ثم اصبح رئيساً لبرلمان الشباب بالانتخاب لمدة ثلاث سنوات ثم عين عضواً باللجنة القانونية لنادى الاتحاد السكندرى وعضواً بنادى الادب بوزارة الشباب والرياضة وكان رئيساً لوفد كلية الحقوق لدى مجلس النواب ومنسقاً للبرامج التدريبية والمحاكم الصورية منذ ٢٠١٦ وحتى تاريخه وتم اختياره عضواً بوفد قيادات شباب مصر وتمثيل مصر بالمؤتمرات الرسمية الكبرى والعربية بعدد من الدول والقى كلمة لدى الامم المتحدة بقمة المناخ معبراً فيها عن خلفيته القانونية مطالباً امريكا والدول العظمى بتحمل اصرار المناخ وتبعها استجابة امريكية بمساعدات كبرى لمصر بنفس المؤتمر</p>
                                <h4>الحياة المهنية :</h4>
                                <p>كانت حافلة بالنجاحات بنسبة خطأ تقترب من الصفر 
حيث ان المكتب نجح فى التصدى لكبرى القضايا وقضايا الاموال العامة والاتجار فى العملة والتزوير الشهيرة والموجودة بالموقع تفصيلاً فى قسم الاخبار
كذلك انتصر فى معارك الرأى القانونية
مع مرتضى منصور والتى انتهت بعزله من نادى الزمالك واصدر الحكم متضمناً نفس الاسباب التى ذكرها بمقالاته باليوم السابع والمصرى اليوم والاخبار وكيرى الصحف
 كما انه تميز فى مجال الشركات وانفتح المكتب بنظم حديثة قامت بخدمة المستثمرين خدمة متميزة ورفعت عن كاهل الشركات كافة الصعوبات بدايةً من التاسيس وحتى التصفية ونسبة الفوز فى القضايا جعلت الشركات تبرم عقوداً ممتدة وطريلة الاجل مع مؤسسة محمود الروبى</p>
                            </div>
                    </div>
                      </div>
                </div>
                <Important/>
                <Footer />
     <div className="whatsapp">
        <a href="https://wa.me/+201023343014">
          <img src="whatsapp (3).png" alt="whatsapp" />
        </a>
      </div>
    </>
  )
}
export default Mahoud
